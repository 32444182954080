/* eslint-disable import/no-duplicates */
import {
  addDays,
  differenceInSeconds,
  format,
  formatISO,
  isToday,
  set,
  subHours,
} from 'date-fns'
import { fr } from 'date-fns/locale'

export const formatDate = (formatStr: string, dateStr?: string): string =>
  format(dateStr ? new Date(dateStr) : new Date(), formatStr, {
    locale: fr,
  })

export const formatDateISOStartAt = (dateStr?: string): string => {
  // Different from today? Return without changes (No need to precise hours with ISO)
  if (dateStr && !isToday(new Date(dateStr))) {
    return dateStr
  }

  // Return today current hour - 1
  return formatISO(
    subHours(set(new Date(), { minutes: 0, seconds: 0, milliseconds: 0 }), 1)
  )
}

export const formatDateISOEndAt = (dateStr?: string): string =>
  formatISO(
    set(dateStr ? new Date(dateStr) : new Date(), { hours: 23, minutes: 59, seconds: 59 })
  )

export const formatDateAddDays = (
  formatStr: string,
  daysToAdd: number,
  dateStr?: string
): string =>
  format(addDays(dateStr ? new Date(dateStr) : new Date(), daysToAdd), formatStr, {
    locale: fr,
  })

export const getSecondsFromNow = (dateStr: string) =>
  differenceInSeconds(new Date(dateStr), new Date())

export const isDatesRangeToday = (startAt: string, endAt: string) =>
  (startAt === null && endAt === null) ||
  (isToday(new Date(startAt)) && isToday(new Date(endAt)))

export const isDateRangeNext7Days = (startAt: string, endAt: string) =>
  isToday(new Date(startAt)) &&
  formatDateAddDays('yyyy-MM-dd', 6) === formatDate('yyyy-MM-dd', endAt)
