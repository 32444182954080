import { ILessonFiltersState } from '@/reducers/lessonFilters'
import {
  IAreaKey,
  ILesson,
  ILessonDecorated,
  ILessonType,
  ILessonVisit,
  ILessonVisitDecorated,
  ISerie,
  ISerieDecorated,
} from '@/types/main'
import { fetchGraphQL } from '@/utils/api-helpers'
import { TIMELINE } from '@/utils/constants'
import { formatDateISOEndAt, formatDateISOStartAt } from '@/utils/date'

import {
  IDualAreaResults,
  IGQLPageVariables,
  IPageGQLResults,
  IPaginationInfo,
  ITrioResults,
} from './api-graphql'
import { INTERMEDIARY_PLACE_FIELDS, MINIMAL_PLACE_FIELDS } from './api-graphql-place'
import { MINIMAL_PROPERTY_FIELDS } from './api-graphql-property'
import { MINIMAL_TEACHER_FIELDS } from './api-graphql-teacher'

export type ILessonGQLResults = IPageGQLResults<ILesson[]>
export type ILessonDecoratedGQLResults = IPageGQLResults<ILessonDecorated[]>
export type ILessonVisitGQLResults = IPageGQLResults<ILessonVisit[]>
export type ILessonVisitDecoratedGQLResults = IPageGQLResults<ILessonVisitDecorated[]>
export type ISerieGQLResults = IPageGQLResults<ISerie[]>
export type ISerieDecoratedGQLResults = IPageGQLResults<ISerieDecorated[]>
export type ILessonDualAreaGQLResults = IDualAreaResults<ILessonGQLResults>
export type ILessonTrioGQLResults = ITrioResults<ILessonGQLResults>

export interface ILessonResultsVariables extends IGQLLessonVariables {
  gqlQuery?: 'Recommended'
  business?: 'b2c' | 'b2b'
}

export const getLessonOnsiteResults = async (
  variables: ILessonResultsVariables = {}
): Promise<ILessonGQLResults> => {
  const { gqlQuery = '', ...gqlVariables } = variables

  const query = `
    query LessonOnsite${gqlQuery}Results (
      $pageIndex: Int = 1
      $itemsPerPage: Int = 25
      $startAt_: [LessonFilter_startAt]
      $areaKey: String
      $postalCodes: [Int]
      $placeIds: [Int]
      $teacherIds: [Int]
      $timeslotIds: [Int]
      $durationIds: [Int]
      $disciplineIds: [Int]
      $levelIds: [Int]
      $objectiveIds: [Int]
      $serieUuid: String
      $tag: String
      $order_: [LessonFilter_order] = [{ startAt: "ASC"}, { uuid: "ASC" }]
    ) {
      lessons: collectionPageQueryOnsite${gqlQuery}Lessons (
        page: $pageIndex
        itemsPerPage: $itemsPerPage
        startAt: $startAt_
        area: $areaKey
        place_postalCode_list: $postalCodes
        place_id_list: $placeIds
        teacher_id_list: $teacherIds
        timeslot_id_list: $timeslotIds
        duration_id_list: $durationIds
        extended_discipline_id_list: $disciplineIds
        level_id_list: $levelIds
        objective_id_list: $objectiveIds
        serie_uuid: $serieUuid
        tagOptional: $tag
        business: "b2c"
        order: $order_
      ) {
        paginationInfo {
          totalCount
          lastPage
        }
        collection {
          ${MINIMAL_LESSON_FIELDS}
          endAt
          duration
          fullBooked
          availableCapacity
          place {
            ${MINIMAL_PLACE_FIELDS}
          }
          teacher {
            ${MINIMAL_TEACHER_FIELDS}
          }
          level {
            ${MINIMAL_PROPERTY_FIELDS}
          }
        }
      }
    }
  `

  const graphqlResult = await fetchGraphQL<{
    lessons: ILessonGQLResults
  }>(query, {
    ...gqlVariables,
    ...(!gqlVariables.startAt_ && { startAt_: [{ after: formatDateISOStartAt() }] }),
  })

  return graphqlResult.data.lessons
}

export const getLessonOnlineResults = async (
  variables: ILessonResultsVariables = {}
): Promise<ILessonGQLResults> => {
  const { gqlQuery = '', ...gqlVariables } = variables

  const query = `
    query LessonOnline${gqlQuery}Results (
      $pageIndex: Int = 1
      $itemsPerPage: Int = 12
      $startAt_: [LessonFilter_startAt]
      $teacherIds: [Int]
      $timeslotIds: [Int]
      $durationIds: [Int]
      $disciplineIds: [Int]
      $levelIds: [Int]
      $objectiveIds: [Int]
      $serieUuid: String
      $tag: String
      $order_: [LessonFilter_order] = [{ startAt: "ASC", uuid: "ASC" }]
    ) {
      lessons: collectionPageQueryOnline${gqlQuery}Lessons (
        page: $pageIndex
        itemsPerPage: $itemsPerPage
        startAt: $startAt_
        teacher_id_list: $teacherIds
        timeslot_id_list: $timeslotIds
        duration_id_list: $durationIds
        extended_discipline_id_list: $disciplineIds
        level_id_list: $levelIds
        objective_id_list: $objectiveIds
        serie_uuid: $serieUuid
        tagOptional: $tag
        business: "b2c"
        order: $order_
      ) {
        paginationInfo {
          totalCount
          lastPage
        }
        collection {
          ${MINIMAL_LESSON_FIELDS}
          endAt
          duration
          fullBooked
          teacher {
            ${MINIMAL_TEACHER_FIELDS}
          }
          level {
            ${MINIMAL_PROPERTY_FIELDS}
          }
        }
      }
    }
  `

  const graphqlResult = await fetchGraphQL<{
    lessons: ILessonGQLResults
  }>(query, {
    ...gqlVariables,
    ...(!gqlVariables.startAt_ && { startAt_: [{ after: formatDateISOStartAt() }] }),
  })

  return graphqlResult.data.lessons
}

export const getLessonResults = async (
  variables: ILessonResultsVariables = {}
): Promise<ILessonGQLResults> => {
  const { gqlQuery = '', ...gqlVariables } = variables

  const query = `
    query Lesson${gqlQuery}Results (
      $pageIndex: Int = 1
      $itemsPerPage: Int = 12
      $startAt_: [LessonFilter_startAt]
      $areaKey: String
      $postalCodes: [Int]
      $placeIds: [Int]
      $teacherIds: [Int]
      $timeslotIds: [Int]
      $durationIds: [Int]
      $disciplineIds: [Int]
      $levelIds: [Int]
      $objectiveIds: [Int]
      $serieUuid: String
      $tag: String
      $business: String
      $order_: [LessonFilter_order] = [{ startAt: "ASC"}, { uuid: "ASC" }]
    ) {
      lessons: collectionPageQuery${gqlQuery}Lessons (
        page: $pageIndex
        itemsPerPage: $itemsPerPage
        startAt: $startAt_
        area: $areaKey
        place_postalCode_list: $postalCodes
        place_id_list: $placeIds
        teacher_id_list: $teacherIds
        timeslot_id_list: $timeslotIds
        duration_id_list: $durationIds
        extended_discipline_id_list: $disciplineIds
        level_id_list: $levelIds
        objective_id_list: $objectiveIds
        serie_uuid: $serieUuid
        tagOptional: $tag
        business: $business
        order: $order_
      ) {
        paginationInfo {
          totalCount
          lastPage
        }
        collection {
          ${MINIMAL_LESSON_FIELDS}
          endAt
          duration
          fullBooked
          availableCapacity
          tagOptional
          place {
            ${MINIMAL_PLACE_FIELDS}
          }
          teacher {
            ${MINIMAL_TEACHER_FIELDS}
          }
          level {
            ${MINIMAL_PROPERTY_FIELDS}
          }
        }
      }
    }
  `

  const graphqlResult = await fetchGraphQL<{
    lessons: ILessonGQLResults
  }>(query, {
    ...gqlVariables,
    ...(!gqlVariables.startAt_ && { startAt_: [{ after: formatDateISOStartAt() }] }),
  })

  return graphqlResult.data.lessons
}

export interface ILessonTypeResultsVariables extends IGQLPageVariables {
  types: ILessonType[]
}
export const getLessonTypeResults = async (
  variables: ILessonTypeResultsVariables
): Promise<ILessonGQLResults> => {
  const query = `
    query TypeLessonResults (
      $pageIndex: Int = 1
      $itemsPerPage: Int = 12
      $startAt_: [LessonFilter_startAt]
      $types: [String]
      $order_: [LessonFilter_order] = [{ startAt: "ASC", uuid: "ASC" }]
    ) {
      lessons: collectionPageQueryLessons (
        page: $pageIndex
        itemsPerPage: $itemsPerPage
        startAt: $startAt_
        type_list: $types
        business: "b2c"
        order: $order_
      ) {
        paginationInfo {
          totalCount
          lastPage
        }
        collection {
          ${MINIMAL_LESSON_FIELDS}
          archivedAt
          endAt
          duration
          fullBooked
          availableCapacity
          description
          information
          place {
            ${INTERMEDIARY_PLACE_FIELDS}
            accessInformation
          }
          teacher {
            ${MINIMAL_TEACHER_FIELDS}
            disciplinesOnly
            bio
          }
          serie {
            ${MINIMAL_SERIE_FIELDS}
          }
          discipline {
            ${MINIMAL_PROPERTY_FIELDS}
            practice: parent {
              ${MINIMAL_PROPERTY_FIELDS}
              pillar: parent {
                ${MINIMAL_PROPERTY_FIELDS}
              }
            }
          }
        }
      }
    }
  `

  const graphqlResult = await fetchGraphQL<{
    lessons: ILessonGQLResults
  }>(query, { ...variables, startAt_: [{ after: formatDateISOStartAt() }] })

  return graphqlResult.data.lessons
}

export interface ILessonResultVariables {
  gqlQuery?: 'Onsite' | 'Online'
  lessonUuid: string
}
export const getLessonResult = async (
  variables: ILessonResultVariables
): Promise<ILesson> => {
  const { gqlQuery = '', ...gqlVariables } = variables

  const query = `
    query ${gqlQuery}LessonResult (
      $lessonUuid: String!
    ) {
      lessons: collectionPageQuery${gqlQuery}Lessons (
        uuid: $lessonUuid
      ) {
        collection {
          ${MINIMAL_LESSON_FIELDS}
          authUserAccess
          archivedAt
          endAt
          duration
          fullBooked
          availableCapacity
          webinarZoomMeeting
          description
          information
          metaOg
          tagOptional
          place {
            ${MINIMAL_PLACE_FIELDS}
            title
            description
            address
            postalCode
            accessInformation
            equipments
            picturePath
            location
            latitude
            longitude
          }
          teacher {
            ${MINIMAL_TEACHER_FIELDS}
            disciplinesOnly
            bio
          }
          serie {
            ${MINIMAL_SERIE_FIELDS}
          }
          discipline {
            ${MINIMAL_PROPERTY_FIELDS}
            practice: parent {
              ${MINIMAL_PROPERTY_FIELDS}
              pillar: parent {
                ${MINIMAL_PROPERTY_FIELDS}
              }
            }
          }
          level {
            ${MINIMAL_PROPERTY_FIELDS}
          }
        }
      }
    }
  `

  const graphqlResult = await fetchGraphQL<{
    lessons: ILessonGQLResults
  }>(query, gqlVariables)

  return graphqlResult.data.lessons.collection[0] ?? null
}

export const getLessonDualAreaResults = async (
  variables: ILessonResultsVariables = {}
): Promise<ILessonDualAreaGQLResults> => {
  const query = `
    query LessonDualAreaResults (
      $itemsPerPage: Int = 5
      $startAt_: [LessonFilter_startAt]
      $teacherIds: [Int]
      $levelIds: [Int]
      $disciplineIds: [Int]
      $placeIds: [Int]
      $serieUuid: String
      $tag: String
      $order_: [LessonFilter_order] = [{ startAt: "ASC", uuid: "ASC" }]
    ) {
      onsite: collectionPageQueryOnsiteLessons (
        itemsPerPage: $itemsPerPage
        startAt: $startAt_
        teacher_id_list: $teacherIds
        level_id_list: $levelIds
        extended_discipline_id_list: $disciplineIds
        place_id_list: $placeIds
        serie_uuid: $serieUuid
        tagOptional: $tag
        business: "b2c"
        order: $order_
      ) {
        collection {
          ${MINIMAL_LESSON_FIELDS}
          endAt
          duration
          fullBooked
          place {
            ${MINIMAL_PLACE_FIELDS}
          }
          teacher {
            ${MINIMAL_TEACHER_FIELDS}
          }
          level {
            ${MINIMAL_PROPERTY_FIELDS}
          }
        }
      }

      online: collectionPageQueryOnlineLessons (
        itemsPerPage: $itemsPerPage
        startAt: $startAt_
        teacher_id_list: $teacherIds
        level_id_list: $levelIds
        extended_discipline_id_list: $disciplineIds
        place_id_list: $placeIds
        serie_uuid: $serieUuid
        tagOptional: $tag
        business: "b2c"
        order: $order_
      ) {
        collection {
          ${MINIMAL_LESSON_FIELDS}
          endAt
          duration
          fullBooked
          teacher {
            ${MINIMAL_TEACHER_FIELDS}
          }
          level {
            ${MINIMAL_PROPERTY_FIELDS}
          }
        }
      }
    }
  `

  const graphqlResult = await fetchGraphQL<{
    onsite: ILessonGQLResults
    online: ILessonGQLResults
  }>(query, { ...variables, startAt_: [{ after: formatDateISOStartAt() }] })

  return graphqlResult.data
}

export const getLessonTrioRecommendedResults = async (
  variables: ILessonResultsVariables = {}
): Promise<ILessonTrioGQLResults> => {
  const query = `
    query LessonTrioRecommendedResults (
      $itemsPerPage: Int = 5
      $startAt_: [LessonFilter_startAt]
      $teacherIds: [Int]
      $levelIds: [Int]
      $disciplineIds: [Int]
      $placeIds: [Int]
      $serieUuid: String
      $tag: String
      $order_: [LessonFilter_order] = [{ startAt: "ASC", uuid: "ASC" }]
    ) {
      b2b: collectionPageQueryRecommendedLessons (
        itemsPerPage: $itemsPerPage
        startAt: $startAt_
        teacher_id_list: $teacherIds
        level_id_list: $levelIds
        extended_discipline_id_list: $disciplineIds
        place_id_list: $placeIds
        serie_uuid: $serieUuid
        tagOptional: $tag
        business: "b2b"
        order: $order_
      ) {
        collection {
          ${MINIMAL_LESSON_FIELDS}
          endAt
          duration
          fullBooked
          place {
            ${MINIMAL_PLACE_FIELDS}
          }
          teacher {
            ${MINIMAL_TEACHER_FIELDS}
          }
          level {
            ${MINIMAL_PROPERTY_FIELDS}
          }
        }
      }

      b2c_onsite: collectionPageQueryOnsiteRecommendedLessons (
        itemsPerPage: $itemsPerPage
        startAt: $startAt_
        teacher_id_list: $teacherIds
        level_id_list: $levelIds
        extended_discipline_id_list: $disciplineIds
        place_id_list: $placeIds
        serie_uuid: $serieUuid
        tagOptional: $tag
        business: "b2c"
        order: $order_
      ) {
        collection {
          ${MINIMAL_LESSON_FIELDS}
          endAt
          duration
          fullBooked
          place {
            ${MINIMAL_PLACE_FIELDS}
          }
          teacher {
            ${MINIMAL_TEACHER_FIELDS}
          }
          level {
            ${MINIMAL_PROPERTY_FIELDS}
          }
        }
      }

      b2c_online: collectionPageQueryOnlineRecommendedLessons (
        itemsPerPage: $itemsPerPage
        startAt: $startAt_
        teacher_id_list: $teacherIds
        level_id_list: $levelIds
        extended_discipline_id_list: $disciplineIds
        place_id_list: $placeIds
        serie_uuid: $serieUuid
        tagOptional: $tag
        business: "b2c"
        order: $order_
      ) {
        collection {
          ${MINIMAL_LESSON_FIELDS}
          endAt
          duration
          fullBooked
          teacher {
            ${MINIMAL_TEACHER_FIELDS}
          }
          level {
            ${MINIMAL_PROPERTY_FIELDS}
          }
        }
      }
    }
  `

  const graphqlResult = await fetchGraphQL<{
    b2b: ILessonGQLResults
    b2c_onsite: ILessonGQLResults
    b2c_online: ILessonGQLResults
  }>(query, { ...variables, startAt_: [{ after: formatDateISOStartAt() }] })

  return graphqlResult.data
}

export interface ILessonVisitResultsVariables extends IGQLPageVariables {
  timeline?: TIMELINE
}
export const getLessonVisitResults = async (
  variables: ILessonVisitResultsVariables = { timeline: TIMELINE.FUTURE }
): Promise<ILessonVisitGQLResults> => {
  const query = `
    query LessonVisitResults (
      $pageIndex: Int = 1
      $itemsPerPage: Int = 12
      $startAt_: [VisitFilter_lesson_startAt]
      $lessonBusiness: String
      $notOrLateCanceled: Boolean = true
      $order_: [VisitFilter_order]
    ) {
      lessonVisits: collectionPageQueryVisits (
        page: $pageIndex
        itemsPerPage: $itemsPerPage
        lesson_startAt: $startAt_
        lesson_business: $lessonBusiness
        notOrLateCanceled: $notOrLateCanceled
        order: $order_
      ) {
        paginationInfo {
          totalCount
          lastPage
        }
        collection {
          ${MINIMAL_LESSONVISIT_FIELDS}
          lesson {
            ${MINIMAL_LESSON_FIELDS}
            endAt
            duration
            fullBooked
            place {
              ${MINIMAL_PLACE_FIELDS}
            }
            teacher {
              ${MINIMAL_TEACHER_FIELDS}
            }
          }
        }
      }
    }
  `

  const graphqlResult = await fetchGraphQL<{
    lessonVisits: ILessonVisitGQLResults
  }>(query, {
    ...buildStartAtVariableFromTimeline(variables.timeline),
    ...variables,
  })

  return graphqlResult.data.lessonVisits
}

export const getLessonVisitOnsiteResults = async (
  variables: ILessonVisitResultsVariables = { timeline: TIMELINE.FUTURE }
): Promise<ILessonVisitGQLResults> => {
  const query = `
    query LessonVisitOnsiteResults (
      $pageIndex: Int = 1
      $itemsPerPage: Int = 12
      $startAt_: [VisitFilter_lesson_startAt]
      $notOrLateCanceled: Boolean = true
      $order_: [VisitFilter_order]
    ) {
      lessonVisits: collectionPageQueryOnsiteVisits (
        page: $pageIndex
        itemsPerPage: $itemsPerPage
        lesson_startAt: $startAt_
        lesson_business: "b2c"
        notOrLateCanceled: $notOrLateCanceled
        order: $order_
      ) {
        paginationInfo {
          totalCount
          lastPage
        }
        collection {
          ${MINIMAL_LESSONVISIT_FIELDS}
          lesson {
            ${MINIMAL_LESSON_FIELDS}
            endAt
            duration
            fullBooked
            place {
              ${MINIMAL_PLACE_FIELDS}
            }
            teacher {
              ${MINIMAL_TEACHER_FIELDS}
            }
          }
        }
      }
    }
  `

  const graphqlResult = await fetchGraphQL<{
    lessonVisits: ILessonVisitGQLResults
  }>(query, {
    ...buildStartAtVariableFromTimeline(variables.timeline),
    ...variables,
  })

  return graphqlResult.data.lessonVisits
}

export const getLessonVisitOnlineResults = async (
  variables: ILessonVisitResultsVariables = { timeline: TIMELINE.FUTURE }
): Promise<ILessonVisitGQLResults> => {
  const query = `
    query LessonVisitOnlineResults (
      $pageIndex: Int = 1
      $itemsPerPage: Int = 12
      $startAt_: [VisitFilter_lesson_startAt]
      $notOrLateCanceled: Boolean = true
      $order_: [VisitFilter_order]
    ) {
      lessonVisits: collectionPageQueryOnlineVisits (
        page: $pageIndex
        itemsPerPage: $itemsPerPage
        lesson_startAt: $startAt_
        lesson_business: "b2c"
        notOrLateCanceled: $notOrLateCanceled
        order: $order_
      ) {
        paginationInfo {
          totalCount
          lastPage
        }
        collection {
          ${MINIMAL_LESSONVISIT_FIELDS}
          lesson {
            ${MINIMAL_LESSON_FIELDS}
            endAt
            duration
            fullBooked
            teacher {
              ${MINIMAL_TEACHER_FIELDS}
            }
          }
        }
      }
    }
  `

  const graphqlResult = await fetchGraphQL<{
    lessonVisits: ILessonVisitGQLResults
  }>(query, {
    ...buildStartAtVariableFromTimeline(variables.timeline),
    ...variables,
  })

  return graphqlResult.data.lessonVisits
}

export const getLessonVisitTrioCounterResults = async (
  variables: ILessonVisitResultsVariables = { timeline: TIMELINE.FUTURE }
): Promise<ITrioResults<number>> => {
  const query = `
    query LessonVisitTrioCounterResults (
      $startAt_: [VisitFilter_lesson_startAt]
      $notOrLateCanceled: Boolean = true
    ) {
      b2b: collectionPageQueryVisits (
        lesson_startAt: $startAt_
        lesson_business: "b2b"
        notOrLateCanceled: $notOrLateCanceled
      ) {
        paginationInfo {
          totalCount
        }
      }

      b2c_onsite: collectionPageQueryOnsiteVisits (
        lesson_startAt: $startAt_
        lesson_business: "b2c"
        notOrLateCanceled: $notOrLateCanceled
      ) {
        paginationInfo {
          totalCount
        }
      }

      b2c_online: collectionPageQueryOnlineVisits (
        lesson_startAt: $startAt_
        lesson_business: "b2c"
        notOrLateCanceled: $notOrLateCanceled
      ) {
        paginationInfo {
          totalCount
        }
      }
    }
  `

  const graphqlResult = await fetchGraphQL<{
    b2b: { paginationInfo: Partial<IPaginationInfo> }
    b2c_onsite: { paginationInfo: Partial<IPaginationInfo> }
    b2c_online: { paginationInfo: Partial<IPaginationInfo> }
  }>(query, {
    ...buildStartAtVariableFromTimeline(variables.timeline),
  })

  return {
    b2b: graphqlResult.data.b2b.paginationInfo.totalCount,
    b2c_onsite: graphqlResult.data.b2c_onsite.paginationInfo.totalCount,
    b2c_online: graphqlResult.data.b2c_online.paginationInfo.totalCount,
  }
}

export const getLessonVisitUpcomingLiveResults = async (
  variables: IGQLPageVariables
): Promise<ILessonVisitGQLResults> => {
  const query = `
    query LessonVisitUpcomingLiveResults (
      $pageIndex: Int = 1
      $itemsPerPage: Int = 12
      $order_: [VisitFilter_order] = [{ lesson_startAt: "ASC", lesson_uuid: "ASC" }]
    ) {
      lessonVisits: collectionPageQueryUpcomingLiveVisits (
        page: $pageIndex
        itemsPerPage: $itemsPerPage
        order: $order_
      ) {
        paginationInfo {
          totalCount
          lastPage
        }
        collection {
          ${MINIMAL_LESSONVISIT_FIELDS}
          webinarZoomJoinUrl
          lesson {
            ${MINIMAL_LESSON_FIELDS}
            endAt
            duration
            fullBooked
            teacher {
              ${MINIMAL_TEACHER_FIELDS}
            }
          }
        }
      }
    }
  `

  const graphqlResult = await fetchGraphQL<{
    lessonVisits: ILessonVisitGQLResults
  }>(query, variables)

  return graphqlResult.data.lessonVisits
}

export interface ILessonVisitResultVariables {
  visitId: number
}
export const getLessonVisitResult = async (
  variables: ILessonVisitResultVariables
): Promise<ILessonVisit> => {
  const query = `
    query LessonVisitResult (
      $visitId: Int!
    ) {
      lessonVisits: collectionPageQueryVisits (
        id: $visitId
      ) {
        collection {
          ${MINIMAL_LESSONVISIT_FIELDS}
        }
      }
    }
  `

  const graphqlResult = await fetchGraphQL<{
    lessonVisits: ILessonVisitGQLResults
  }>(query, variables)

  return graphqlResult.data.lessonVisits.collection[0] ?? null
}

export const getSerieResults = async (
  variables: IGQLPageVariables
): Promise<ISerieGQLResults> => {
  const query = `
    query SerieResults (
      $pageIndex: Int = 1
      $itemsPerPage: Int = 12
    ) {
      series: collectionPageQuerySeries (
        page: $pageIndex
        itemsPerPage: $itemsPerPage
        canceled: false
      ) {
        collection {
          ${MINIMAL_SERIE_FIELDS}
          title
          startDate
          endDate
          startTime
          duration
          description
          information
          place {
            ${INTERMEDIARY_PLACE_FIELDS}
            accessInformation
            equipments
          }
          teacher {
            ${MINIMAL_TEACHER_FIELDS}
            bio
          }
          discipline {
            ${MINIMAL_PROPERTY_FIELDS}
            practice: parent {
              ${MINIMAL_PROPERTY_FIELDS}
              pillar: parent {
                ${MINIMAL_PROPERTY_FIELDS}
              }
            }
          }
          level {
            ${MINIMAL_PROPERTY_FIELDS}
          }
        }
      }
    }
  `

  const graphqlResult = await fetchGraphQL<{
    series: ISerieGQLResults
  }>(query, variables)

  return graphqlResult.data.series
}

export interface ISerieResultVariables {
  serieUuid: string
}
export const getSerieResult = async (
  variables: ISerieResultVariables
): Promise<ISerie> => {
  const query = `
    query SerieResult (
      $serieUuid: String!
    ) {
      series: collectionPageQuerySeries (
        uuid: $serieUuid
      ) {
        collection {
          ${MINIMAL_SERIE_FIELDS}
          title
          startDate
          endDate
          startTime
          duration
          description
          information
          metaOg
          place {
            ${INTERMEDIARY_PLACE_FIELDS}
            accessInformation
            equipments
          }
          teacher {
            ${MINIMAL_TEACHER_FIELDS}
            bio
          }
          discipline {
            ${MINIMAL_PROPERTY_FIELDS}
            practice: parent {
              ${MINIMAL_PROPERTY_FIELDS}
              pillar: parent {
                ${MINIMAL_PROPERTY_FIELDS}
              }
            }
          }
          level {
            ${MINIMAL_PROPERTY_FIELDS}
          }
        }
      }
    }
  `

  const graphqlResult = await fetchGraphQL<{
    series: ISerieGQLResults
  }>(query, variables)

  return graphqlResult.data.series.collection[0] ?? null
}

// Mapped to reducer
export interface IGQLLessonVariables extends IGQLPageVariables {
  startAt_?: { [key in 'after' | 'before']?: string }[]
  areaKey?: IAreaKey
  postalCodes?: number[]
  placeIds?: number[]
  teacherIds?: number[]
  timeslotIds?: number[]
  durationIds?: number[]
  levelIds?: number[]
  disciplineIds?: number[]
  objectiveIds?: number[]
  serieUuid?: string
  tag?: string
}

export const buildGqlVariablesFromLessonFilters = (
  lessonFiltersState: ILessonFiltersState
): IGQLLessonVariables => {
  const {
    pageIndex,
    startAt,
    endAt,
    areaKey,
    postalCodes,
    placeIds,
    teacherIds,
    timeslotIds,
    durationIds,
    levelIds,
    disciplineIds,
    objectiveIds,
    serieUuid,
    tag,
  } = lessonFiltersState

  return {
    pageIndex,
    startAt_: [
      {
        after: formatDateISOStartAt(startAt),
        ...(endAt && { before: formatDateISOEndAt(endAt) }),
      },
    ],
    ...(areaKey && { areaKey }),
    ...(postalCodes.length && { postalCodes }),
    ...(placeIds.length && { placeIds }),
    ...(teacherIds.length && { teacherIds }),
    ...(timeslotIds.length && { timeslotIds }),
    ...(durationIds.length && { durationIds }),
    ...(levelIds.length && { levelIds }),
    ...(disciplineIds.length && { disciplineIds }),
    ...(objectiveIds.length && { objectiveIds }),
    ...(serieUuid && { serieUuid }),
    ...(tag && { tag }),
  }
}

const buildStartAtVariableFromTimeline = (
  timeline: ILessonVisitResultsVariables['timeline']
) => ({
  startAt_: [
    {
      [timeline === TIMELINE.FUTURE ? 'after' : 'before']: formatDateISOStartAt(),
    },
  ],
  order_: [
    {
      lesson_startAt: timeline === TIMELINE.FUTURE ? 'ASC' : 'DESC',
      lesson_uuid: 'ASC',
    },
  ],
})

export const MINIMAL_SERIE_FIELDS = `
  id
  ulid
  type
  area
  authUserFavorite
`
export const MINIMAL_LESSON_FIELDS = `
  id
  uuid
  title
  type
  area
  startAt
  creditEnable
  waitlistEnable
  authUserFavorite
  authUserVisits
  authUserSurveyStatus
  replayAvailable
  userInvitationAllowed
  userCancellationAllowed
  serie {
    ${MINIMAL_SERIE_FIELDS}
  }
`
const MINIMAL_LESSONVISIT_FIELDS = `
  id
  _id
  canceledAt
  lateCanceled
  waitlist
  signIn
  cancellationPolicy
`
