import { IAreaKey, IPlace, IPlaceDecorated } from '@/types/main'
import { fetchGraphQL } from '@/utils/api-helpers'

import { IGQLPageVariables, IPageGQLResults } from './api-graphql'

export type IPlaceGQLResults = IPageGQLResults<IPlace[]>
export type IPlaceDecoratedGQLResults = IPageGQLResults<IPlaceDecorated[]>

export interface IPlaceResultsVariables extends IGQLPageVariables {
  areaKey?: IAreaKey
}
export const getPlaceResults = async (
  variables: IPlaceResultsVariables = {}
): Promise<IPlaceGQLResults> => {
  const query = `
    query PlaceResults (
      $pageIndex: Int = 1
      $itemsPerPage: Int = 12
      $areaKey: String
      $postalCodes: [Int]
    ) {
      places: collectionPageQueryPlaces (
        page: $pageIndex
        itemsPerPage: $itemsPerPage
        area: $areaKey
        postalCode_list: $postalCodes
      ) {
        paginationInfo {
          totalCount
          lastPage
        }
        collection {
          ${INTERMEDIARY_PLACE_FIELDS}
        }
      }
    }
  `

  const graphqlResult = await fetchGraphQL<{
    places: IPlaceGQLResults
  }>(query, variables)

  return graphqlResult.data.places
}

export interface IPlaceResultVariables {
  placeId: number
}
export const getPlaceResult = async (
  variables: IPlaceResultVariables
): Promise<IPlace> => {
  const query = `
    query PlaceResult (
      $placeId: Int!
    ) {
      places: collectionPageQueryPlaces (
        id: $placeId
      ) {
        collection {
          ${MINIMAL_PLACE_FIELDS}
          title
          postalCode
          area
          description
          location
          picturePath
          equipments
        }
      }
    }
  `

  const graphqlResult = await fetchGraphQL<{
    places: IPlaceGQLResults
  }>(query, variables)

  return graphqlResult.data.places.collection[0] ?? null
}

export const MINIMAL_PLACE_FIELDS = `
  id
  _id
  city
  authUserFavorite
  latitude
  longitude
  outdoor
`
export const INTERMEDIARY_PLACE_FIELDS = `
  ${MINIMAL_PLACE_FIELDS}
  title
  address
  postalCode
  area
  picturePath
`
